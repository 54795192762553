exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-post-js": () => import("./../../../src/pages/blogPost.js" /* webpackChunkName: "component---src-pages-blog-post-js" */),
  "component---src-pages-guides-js": () => import("./../../../src/pages/guides.js" /* webpackChunkName: "component---src-pages-guides-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-law-js": () => import("./../../../src/pages/law.js" /* webpackChunkName: "component---src-pages-law-js" */),
  "component---src-pages-page-slug-js": () => import("./../../../src/pages/{Page.slug}.js" /* webpackChunkName: "component---src-pages-page-slug-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-product-page-js": () => import("./../../../src/pages/productPage.js" /* webpackChunkName: "component---src-pages-product-page-js" */),
  "component---src-pages-recruiting-js": () => import("./../../../src/pages/recruiting.js" /* webpackChunkName: "component---src-pages-recruiting-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-seo-page-js": () => import("./../../../src/pages/seoPage.js" /* webpackChunkName: "component---src-pages-seo-page-js" */),
  "component---src-pages-subtitles-js": () => import("./../../../src/pages/subtitles.js" /* webpackChunkName: "component---src-pages-subtitles-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-transkribering-js": () => import("./../../../src/pages/transkribering.js" /* webpackChunkName: "component---src-pages-transkribering-js" */)
}

