import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './i18n/en/translation.json';
import sv from './i18n/sv/translation.json';
import no from './i18n/no/translation.json';

const resources = {
  en: {
    translation: en,
  },
  sv: {
    translation: sv,
  },
  no: {
    translation: no,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'sv',
});

export default i18n;
